
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
@import 'CarouselButtonGroup.bel.module';

.wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.buttonGroup {
  display: flex;
  width: 110px;
  justify-content: space-between;

  @include breakpoint($sm) {
    width: 70px;
  }
}

.arrow {
  background-color: $black;

  &:hover {
    background-color: $primary-hover;
  }

  svg {

    path {
      fill: $white;
    }
  }
}
