:global(body.region__bel) {

  .info {
    background: url('/images/dividor-white.svg') no-repeat fixed center calc(100% - 20px);

    @include breakpoint($sm) {
      background-position: center calc(100% - 15px);
    }
  }
}
