
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
@import 'Best.bel.module';

.header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;

  @include breakpoint($sm) {
    margin-bottom: 20px;
  }
}

.title {
  @include h2;

  opacity: 0.8;
}

.cardsGrid {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
