:global(body.region__bel) {

  .title {
    color: $primary;
  }

  .button {
    background-color: transparent;
    color: $black;
    padding: 0;
    border: none;

    svg {
      transform: rotate(270deg);
      width: 30px;

      @include breakpoint($sm) {
        margin-left: 7px;
      }

      path {
        fill: $black;
      }
    }

    &:hover {
      color: $primary;

      svg {

        path {
          fill: $primary;
        }
      }
    }
  }

  @include breakpoint($sm) {

    .buttonText {
      width: 35px;
    }
  }
}
