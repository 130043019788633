
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  .iconBg {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background-color: $pushkin-card-fill;
  }

  svg {
    position: absolute;
    fill: $pushkin-card-bg;
  }
}
