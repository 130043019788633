
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  position: relative;
  width: 580px;
  height: 360px;
  display: flex;

  @include breakpoint($sm) {
    width: 335px;
    height: 690px;
    flex-direction: column;
    margin: 0 auto;
  }

  &:hover {

    > .info {
      background-color: $excursion-main-card-bg-hover;
    }
  }

  &.catalog {
    color: $black;

    @include breakpoint($md) {
      width: 335px;
      height: 690px;
      flex-direction: column;
    }

    &:hover {

      > .info {
        background-color: $excursion-card-bg-hover;
      }

      .title {
        overflow: hidden;
      }
    }

    .badge {
      background-color: $black-5;
      color: $black;
    }

    .image {
      @include breakpoint($md) {
        width: 100%;
        height: 410px;
      }
    }

    > .info {
      border: 1px solid $excursion-card-border;
      background-color: $excursion-card-bg-default;

      @include breakpoint($md) {
        width: 100%;
      }

      .date,
      .price {
        color: $black;

        svg {

          path {
            fill: $black;
          }
        }
      }

      .duration {
        color: $black-60;
      }

      .title {
        color: $black;
        overflow: hidden;
      }

      .description {
        color: $black;
      }
    }
  }
}

.info {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $excursion-main-card-bg-default;
  transition: $card-bg-transition;
  padding: 60px 40px 30px;
  width: 50%;
  height: 100%;

  @include breakpoint($sm) {
    width: 100%;
  }
}

.duration {
  @include t3;

  color: rgba($excursion-main-card-text, 0.7);
}

.title {
  margin-top: 5px;

  @include h5;

  color: $excursion-main-card-text;
  overflow: hidden;
}

.description {
  margin-top: 14px;

  @include p3;

  color: $excursion-main-card-text;
  overflow: hidden;

  @include breakpoint($md-and-sm) {
    display: none !important;
  }
}

.price {
  @include t3;

  color: $excursion-main-card-text;
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-top: 6px;
  margin-bottom: -2px;

  svg {
    margin-right: 10px;

    path {
      fill: $excursion-main-card-text;
    }
  }
}

.badge {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 13px;

  @include t5;

  text-transform: uppercase;
  text-align: center;
  background-color: $excursion-main-card-badge-bg;
  color: $excursion-main-card-text;
}

.image {
  height: 100%;
  width: 50%;
  flex-shrink: 0;

  @include breakpoint($sm) {
    width: 100%;
    height: 410px;
  }

  img {
    height: 100%;
  }
}
