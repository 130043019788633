:global(body.region__bel) {

  .arrow {
    background-color: $white;

    &:hover {
      color: $white;
      background-color: $primary;

      svg {

        path {
          fill: $white;
        }
      }
    }

    svg {

      path {
        fill: $black;
      }
    }
  }
}
